import React, { useEffect, useState, useContext, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyLoadQuery, graphql } from 'react-relay';
import classnames from 'classnames';

import {
  getList,
  getInnerTabs,
  getTabsData,
  topTabsList,
  innerTabsList,
  getFinalType,
  OVERVIEW,
  JUST_CONTENT,
  POSTING_CONTENT,
  DELIVERABLES,
} from './util';
import styles from './NewCampaignTabs.pcss';

import { useLocalStore } from 'Hooks/useLocalStore';
import { LocalStoreType } from 'Types/externalStore';
import { amplitude } from 'Helpers/amplitude';
import { getCampaignTypeLink } from 'Util/links';
import InlineTabs from 'Components/ui/InlineTabs/InlineTabs';
import { Props as TabProps } from 'Components/ui/InlineTabs/InlineTab/InlineTab';
import Tabs from 'Components/ui/Tabs/Tabs';
import { NewCampaignTabsQuery as QueryType } from 'GraphTypes/NewCampaignTabsQuery.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

const PERFORMANCE_PAGES = [OVERVIEW, JUST_CONTENT, POSTING_CONTENT, DELIVERABLES];

type TypesData = {
  topTab?: string;
  innerTab?: string;
};

const NewCampaignTabsQuery = graphql`
  query NewCampaignTabsQuery($id: ID!) {
    currentUser {
      admin
    }
    campaign(id: $id) {
      type
      showShipmentTab
      publishingRequired
      publishedCreatives: creatives(publishedPosts: true) {
        hasItems
      }
      approvedCreatives: creatives(publishedPosts: false) {
        hasItems
      }
      brief {
        ... on V2Brief {
          productDelivery
        }
      }
      applicantsWithUnreadMesseges: projects(
        stages: [ACCEPTED]
        archived: false
        withUnreadEvents: true
      ) {
        hasItems
      }
      contentCreationUnreadMesseges: projects(
        stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED, COMPLETED]
        archived: false
        withUnreadEvents: true
      ) {
        hasItems
      }
      acceptedCounter: projects(
        stages: [ACCEPTED]
        hasBrandMessageBeforeLaunch: false
        archived: false
        withUnreadEvents: true
      ) {
        totalCount
      }
      messagedCounter: projects(
        stages: [ACCEPTED]
        hasBrandMessageBeforeLaunch: true
        archived: false
        withUnreadEvents: true
      ) {
        totalCount
      }
      inProgressCounter: projects(
        stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED]
        archived: false
        withUnreadEvents: true
      ) {
        totalCount
      }
      completedCounter: projects(stages: [COMPLETED], archived: false, withUnreadEvents: true) {
        totalCount
      }
    }
  }
`;

interface Props {
  tabMessage?: { targetTab: string; value: number | string };
}

type tabNameType =
  | 'applicants'
  | 'messaged'
  | 'invitation-sent'
  | 'archived'
  | 'content-creation'
  | 'completed'
  | 'declined'
  | 'hired';

const tabsWithNotifications: Array<tabNameType> = [
  'messaged',
  'invitation-sent',
  'archived',
  'completed',
  'declined',
  'hired',
];

const NewCampaignTabs: FC<Props> = ({ tabMessage }) => {
  const { id = '', type: defaultType = '' } = useParams<{ type: string; id: string }>();
  const navigate = useNavigate();

  const type = getFinalType(defaultType);

  const [localData, setLocalData] = useLocalStore(
    'сampaignPerformance'
  ) as LocalStoreType['сampaignPerformance'];

  const isLabelShownByDefault = localData.shown || PERFORMANCE_PAGES.includes(defaultType);

  const [tabsData, setTabsData] = useState<TypesData>();
  const [isSticky, setSticky] = useState(false);
  const [labelForPerformanceVisible, setLabelForPerformanceVisible] =
    useState(isLabelShownByDefault);

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const data = useLazyLoadQuery<QueryType>(NewCampaignTabsQuery, { id });

  const admin = data.currentUser?.admin;

  const innerTabs = getInnerTabs(data);

  const canUseOutreachTool = !!(hasFeatureFlag('outreach_tool') || admin);
  const list = getList(data, canUseOutreachTool, labelForPerformanceVisible);

  const getTabsInfo = (type: string) => {
    const defaultTopTab = topTabsList.includes(type) ? type : '';
    const defaultInnerTab = innerTabsList.includes(type) ? type : '';
    const topTabByInnerTab =
      !defaultTopTab && defaultInnerTab ? getTabsData(defaultInnerTab).topTab : defaultTopTab;
    const innerTabByTopTab =
      !defaultInnerTab && defaultTopTab ? getTabsData(defaultTopTab).innerTab : defaultInnerTab;
    return { defaultTopTab: topTabByInnerTab, defaultInnerTab: innerTabByTopTab };
  };

  const handleScroll = () => {
    const stickyPoint = document.getElementById('campaign-info-container')?.clientHeight || 0;
    const scrollTop = Number(document.documentElement.scrollTop);
    if (scrollTop >= stickyPoint) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    amplitude.sendEvent<544>({
      id: '544',
      category: 'campaign_tabs',
      name: 'performance_new_label_show',
      param: { campaign_id: id },
    });
  }, [labelForPerformanceVisible]);

  useEffect(() => {
    if (PERFORMANCE_PAGES.includes(defaultType)) {
      setLocalData({ shown: true });
      setLabelForPerformanceVisible(true);
    }
  }, [defaultType]);

  useEffect(() => {
    const { defaultTopTab, defaultInnerTab } = getTabsInfo(type);
    setTabsData({ topTab: defaultTopTab, innerTab: defaultInnerTab });
  }, [type]);

  const topTabs: TabProps[] = list.map((item) => {
    const handleTopTabClick = () => {
      amplitude.sendEvent<523>({
        id: '523',
        category: 'campaign_tabs',
        name: 'click',
        param: { section: item.id, campaign_id: id, withNewLabel: item.withNewLabel },
      });
      const newLink = getCampaignTypeLink({ campaignId: id, path: item.id });
      navigate(newLink, { replace: true });
    };
    return {
      ...item,
      active: tabsData?.topTab === item.id,
      onClick: handleTopTabClick,
      tabMessage: tabMessage,
      hasNotification: tabMessage?.targetTab === item.id,
      notificationEnabled: tabsWithNotifications.includes(item.id as tabNameType),
    };
  });

  const innerTabList = innerTabs[tabsData?.topTab as keyof typeof innerTabs]?.map((item) => {
    const handleInnerTabClick = () => {
      amplitude.sendEvent<523>({
        id: '523',
        category: 'campaign_tabs',
        name: 'click',
        param: { section: item.id, campaign_id: id },
      });
      const newLink = getCampaignTypeLink({ campaignId: id, path: item.id });
      navigate(newLink, { replace: true });
    };
    return {
      ...item,
      active: tabsData?.innerTab === item.id,
      onClick: handleInnerTabClick,
      tabKey: item.id,
      tabMessage: tabMessage,
      hasNotification: tabMessage?.targetTab === item.id,
      notificationEnabled: tabsWithNotifications.includes(item.id as tabNameType),
    };
  });

  return (
    <div className={classnames(styles.root, { [styles.isSticky]: isSticky })}>
      <div className={styles.topTabs}>
        <InlineTabs tabs={topTabs} />
      </div>
      {!!(innerTabList && innerTabList.length > 1) && (
        <div className={styles.innerTabsWrap}>
          <div className={styles.innerTabs}>
            <Tabs tabs={innerTabList} className={styles.tabs} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewCampaignTabs;

export { NewCampaignTabsQuery };
