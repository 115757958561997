import compact from 'lodash/compact';

import { AWAITING_LIST, RESULTS } from 'Constants/general';
import { NewCampaignTabsQuery$data } from 'GraphTypes/NewCampaignTabsQuery.graphql';

export const APPLICANTS = 'applicants';
export const HIRED = 'hired';
export const CREATORS = 'creators';
export const OUTREACH = 'outreach';
export const SHIPPING = 'shipment';
export const DELIVERABLES = 'deliverables';
export const RECIEVED_APPLICANTS = 'received-applicants';
export const MESSAGED = 'messaged';
export const INVITATION_SENT = 'invitation-sent';
export const ARCHIVED = 'archived';
export const CONTENT_CREATION = 'content-creation';
export const COMPLETED = 'completed';
export const DECLINED = 'declined';
export const POSTING_CONTENT = 'posting-content';
export const JUST_CONTENT = 'just-content';
export const OVERVIEW = 'overview';

export const topTabsList = [APPLICANTS, OUTREACH, CREATORS, HIRED, SHIPPING, DELIVERABLES];
export const innerTabsList = [
  RECIEVED_APPLICANTS,
  MESSAGED,
  INVITATION_SENT,
  ARCHIVED,
  CONTENT_CREATION,
  COMPLETED,
  DECLINED,
  POSTING_CONTENT,
  JUST_CONTENT,
  OVERVIEW,
];

export const getFinalType = (type: string) => {
  if (type === CREATORS) {
    return CONTENT_CREATION;
  } else if (type === AWAITING_LIST) {
    return INVITATION_SENT;
  } else if (type === RESULTS) {
    return OVERVIEW;
  }
  return type;
};

export const getList = (
  data: NewCampaignTabsQuery$data,
  canUseOutreachTool: boolean,
  labelForPerformanceVisible: boolean
) => {
  const productDelivery = data.campaign?.brief?.productDelivery;
  const showShipmentTab = data.campaign?.showShipmentTab;
  const applicantsWithUnreadMesseges = data.campaign?.applicantsWithUnreadMesseges.hasItems;
  const contentCreationUnreadMesseges = data.campaign?.contentCreationUnreadMesseges.hasItems;

  return compact([
    { id: APPLICANTS, msg: 'campaign.tabs.applicants', hasUnread: !!applicantsWithUnreadMesseges },
    canUseOutreachTool ? { id: OUTREACH, msg: 'campaign.tabs.outreach' } : null,
    { id: HIRED, msg: 'campaign.tabs.hired', hasUnread: !!contentCreationUnreadMesseges },
    productDelivery &&
    productDelivery === 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS' &&
    showShipmentTab
      ? { id: SHIPPING, msg: 'campaign.tabs.shipping' }
      : null,
    {
      id: DELIVERABLES,
      msg: 'campaign.tabs.deliverables',
      withNewLabel: !labelForPerformanceVisible,
    },
  ]);
};

export const getInnerTabs = (data: NewCampaignTabsQuery$data) => {
  const campaignType = data.campaign?.type;
  const publishingRequired = data.campaign?.publishingRequired;
  const hasPublishedCreatives = data.campaign?.publishedCreatives.hasItems;
  const hasApprovedCreatives = data.campaign?.approvedCreatives.hasItems;
  const acceptedCounter = data.campaign?.acceptedCounter.totalCount || 0;
  const messagedCounter = data.campaign?.messagedCounter.totalCount || 0;
  const inProgressCounter = data.campaign?.inProgressCounter.totalCount || 0;
  const completedCounter = data.campaign?.completedCounter.totalCount || 0;

  return {
    applicants: [
      {
        id: RECIEVED_APPLICANTS,
        msg: 'campaign.tabs.recieved_applicants',
        unreadCount: acceptedCounter,
      },
      {
        id: MESSAGED,
        msg: 'campaign.tabs.messaged',
        unreadCount: messagedCounter,
      },
      {
        id: INVITATION_SENT,
        msg: 'campaign.tabs.invitation_sent',
      },
      {
        id: ARCHIVED,
        msg: 'campaign.tabs.archived',
      },
    ],
    hired: [
      {
        id: CONTENT_CREATION,
        msg: 'campaign.tabs.content_creation',
        unreadCount: inProgressCounter,
      },
      {
        id: COMPLETED,
        msg: 'campaign.tabs.completed',
        unreadCount: completedCounter,
      },
      {
        id: DECLINED,
        msg: 'campaign.tabs.declined',
      },
    ],
    deliverables: compact([
      {
        id: OVERVIEW,
        msg: 'campaign.tabs.overview',
      },
      publishingRequired || hasPublishedCreatives
        ? {
            id: POSTING_CONTENT,
            msg: 'campaign.tabs.posting_content',
          }
        : null,
      campaignType === 'CONTENT_CREATION' ||
      campaignType === 'PRODUCT_SEEDING' ||
      hasApprovedCreatives
        ? {
            id: JUST_CONTENT,
            msg: 'campaign.tabs.just_content',
          }
        : null,
    ]),
  };
};

export const getTabsData = (id: string) => {
  switch (id) {
    case APPLICANTS:
    case RECIEVED_APPLICANTS:
      return { topTab: APPLICANTS, innerTab: RECIEVED_APPLICANTS };
    case MESSAGED:
      return { topTab: APPLICANTS, innerTab: MESSAGED };
    case INVITATION_SENT:
      return { topTab: APPLICANTS, innerTab: INVITATION_SENT };
    case ARCHIVED:
      return { topTab: APPLICANTS, innerTab: ARCHIVED };
    case HIRED:
    case CREATORS:
    case CONTENT_CREATION:
      return { topTab: HIRED, innerTab: CONTENT_CREATION };
    case COMPLETED:
      return { topTab: HIRED, innerTab: COMPLETED };
    case DECLINED:
      return { topTab: HIRED, innerTab: DECLINED };
    case POSTING_CONTENT:
      return { topTab: DELIVERABLES, innerTab: POSTING_CONTENT };
    case JUST_CONTENT:
      return { topTab: DELIVERABLES, innerTab: JUST_CONTENT };
    case DELIVERABLES:
    case OVERVIEW:
      return { topTab: DELIVERABLES, innerTab: OVERVIEW };

    default:
      return { topTab: '', innerTab: '' };
  }
};
